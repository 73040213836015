import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ROLES } from "@/graphql/admin/role.js";
import { WEBSITE } from "@/config";
import RoleForm from "./components/RoleForm.vue";
export default {
  components: {
    RoleForm: RoleForm
  },
  created: function created() {
    document.title = "\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 | ".concat(WEBSITE);
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32",
      route: "/admin/setting"
    }, {
      title: "\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19"
    }]);
  },
  data: function data() {
    return {
      editId: null,
      page: 1,
      pageSize: 10,
      status: "",
      search: ""
    };
  },
  methods: {
    onClose: function onClose() {
      this.editId = null;
    },
    setEdit: function setEdit(id) {
      this.editId = id;
    },
    drawAccess: function drawAccess(permission) {
      if (permission.readableRole && permission.writeableRole) {
        return "\u0E14\u0E39\u0E41\u0E25\u0E30\u0E41\u0E01\u0E49\u0E44\u0E02 ".concat(permission.permissionInfo.permissionTitle);
      }

      if (permission.readableRole) {
        return "\u0E14\u0E39\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E40\u0E14\u0E35\u0E22\u0E27 ".concat(permission.permissionInfo.permissionTitle);
      }

      if (permission.writeableRole) {
        return "\u0E14\u0E39\u0E41\u0E25\u0E30\u0E41\u0E01\u0E49\u0E44\u0E02 ".concat(permission.permissionInfo.permissionTitle);
      }

      return permission.permissionInfo.permissionTitle;
    },
    onRefetch: function onRefetch() {
      this.$apollo.queries.systemRoleList.refetch();
    }
  },
  apollo: {
    systemRoleList: {
      query: GET_ROLES,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      }
    }
  }
};