import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { gql } from "@apollo/client";
export var DELETE_ROLE = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($id: ID!) {\n    deleteRole(_id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPDATE_ROLE = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($input: RoleInput) {\n    updateRole(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var CREATE_ROLE = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($input: RoleInput) {\n    createRole(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var GET_ROLE_MASTERS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query {\n    permissionList {\n      records {\n        _id\n        permissionId\n        permissionName\n        permissionTitle\n      }\n    }\n  }\n"])));
export var GET_ROLE = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query($_id: ID) {\n    systemRole(_id: $_id) {\n      isSuccess\n      message\n      data {\n        _id\n        roleId\n        role_no\n        role_name\n        permissionRole {\n          _id\n          permissionId\n          permissionInfo {\n            permissionTitle\n          }\n          readableRole\n          writeableRole\n        }\n      }\n    }\n  }\n"])));
export var GET_ROLES = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query($page: Int!, $pageSize: Int!, $search: String) {\n    systemRoleList(page: $page, pageSize: $pageSize, search: $search) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        roleId\n        role_no\n        role_name\n        permissionRole {\n          _id\n          permissionInfo {\n            permissionTitle\n          }\n          readableRole\n          writeableRole\n        }\n        total_role_used\n        moreNumber\n      }\n    }\n  }\n"])));
export var GET_ROLES_SELECT = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query {\n    systemRoleSelect {\n      records {\n        _id\n        roleId\n        role_name\n      }\n    }\n  }\n"])));